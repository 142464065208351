<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>
          {{ $t("quota_storage.settingdefault") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-6">
          <v-card class="py-6 mt-0 ml-4 mr-4">
            <v-card-title class="pb-0"
              ><v-icon class="pr-2">mdi-account</v-icon
              >{{ $t("quota_storage.text_set_quota_head") }}</v-card-title
            >
            <v-card-text class="mt-4">
              <v-layout row wrap justify-center>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="default_business.quota_storage"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_set_quota')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="default_business.remain_storage"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_remain')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="default_business.storage_used"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_used')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-layout>
            </v-card-text>
            <v-card-title
              ><v-icon class="pr-2">settings</v-icon
              >{{ $t("quota_storage.settingdefault") }}</v-card-title
            >
            <v-card-actions class="mt-4">
              <v-layout row wrap class="px-12">
                <v-flex lg6 xs12>
                  <v-text-field
                    v-model="edit_storage"
                    :label="$t('quota_storage.text_edit')"
                    dense
                    outlined
                    hide-details
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-flex>
                <v-flex lg6 xs12>
                  <v-select
                    v-model="selectsize"
                    :items="itemsize"
                    outlined
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="close()">{{
            $t("admin.close")
          }}</v-btn>
          <v-btn
            @click="fn_update_default_storge()"
            :color="color.theme"
            :loading="loadprogress"
            class="white--text"
            >{{ $t("admin.submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "default_business"],
  data: function() {
    return {
      loadprogress: false,
      selectsize: "GB",
      itemsize: ["KB", "MB", "GB", "TB"],
      quota_storage: "",
      remain_storage: "",
      used_storage: "",
      edit_storage: 0,
      storau: "1 GB",
      storan: 50,
      selectSize: "",
      filename: "",
      createprogress: false,
      zoom: 0,
      test: 1,
      unitremain: "",
      updatestorage: 0,
      maxstorage: 0,
      remain_storage: 0,
      sizeStorage: ["MB", "GB"],
      totalstorage: 0,
      unit_storage_account: "",
      plusnum: 1,
      maxstorageupdate: "",
      mutiprogress: false,
      progressdatasettingstorage: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        console.log(this.default_business, this.remain_business);
        this.remain_storage = this.default_business["remain_storage"];
        this.used_storage = this.default_business["storage_used"];
        this.quota_storage = this.default_business["quota_storage"];
        this.convertstorage(this.default_business.quota_storage);
      } else {
        //this.$emit("closemanagestorage");
      }
    },
  },
  methods: {
    close() {
      if (this.mutiprogress === true) {
        this.test = 1;
        this.loadprogress = false;
        this.mutiprogress = false;
        this.progressdatasettingstorage = [];
        this.$emit("closemanagestorage");
      } else {
        this.$emit("close");
        this.test = 1;
        this.loadprogress = false;
        this.mutiprogress = false;
      }
    },
    convertstorage(parameter) {
      console.log("parameter", parameter);
      let res_ = parameter.split(" ");
      this.edit_storage = parseFloat(res_[0]);
      this.selectsize = res_[1] === "set" ? "KB" : res_[1];
      console.log(this.edit_storage);
    },
    check_statusmuti() {
      if (this.statusmulti === "ONE") {
        this.fn_update_storge();
      } else {
        this.fn_muti_update_storge();
      }
    },
    async fn_update_default_storge() {
      var payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        unit: this.selectsize,
        quota_storage:
          this.edit_storage.toString() === "NaN" || NaN
            ? "0"
            : this.edit_storage.toString(),
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/admin/set_default_quota_storage",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
              this.$emit("closereload");
              Toast.fire({
                icon: "success",
                title: this.$t("admintoast.settingsuccess"),
              });
          } else if (response.data.errorMessage === "Not enough storage.") {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("admintoast.notenoughstorage"),
            });
          } else {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("admintoast.errormanagequotastorage"),
            });
          }
        })
        .catch((error) => {
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("admintoast.errormanagequotastorage"),
          });
        });
    },
  },
};
</script>
<style></style>
